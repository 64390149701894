import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataService } from 'src/shared/services/data/data.service';

@Component({
  selector: 'app-mca-coins',
  templateUrl: './mca-coins.component.html',
  styleUrls: ['./mca-coins.component.scss']
})
export class McaCoinsComponent implements OnInit {

  conversionData: any;
  constructor(
    @Optional() public dialogRef: MatDialogRef<McaCoinsComponent>,
    public dataService: DataService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data
  ) {
    // console.log(data);
    this.conversionData = data;
  }

  ngOnInit() {
  }


  close(){
    this.dialogRef.close();
  }

   // mouse over sound
   playSound(){
    this.dataService.hoverSound.emit('play');
  }

}
