import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { DataService } from 'src/shared/services/data/data.service';

@Component({
  selector: 'app-presentation-pop-up',
  templateUrl: './presentation-pop-up.component.html',
  styleUrls: ['./presentation-pop-up.component.scss']
})
export class PresentationPopUpComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<PresentationPopUpComponent>,private router : Router,private dataService : DataService) { }

  ngOnInit() {
  }


  close(){
    this.dialogRef.close();
  }

  backToHomePage()
  {
    // this.router.navigate(['../../../dashboard']);
    this.dialogRef.close(1);
  }

  playSound(){
    this.dataService.hoverSound.emit('play');
  }

}
