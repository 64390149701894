import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class isUserExists implements CanActivate {
  constructor(private router: Router) { }

  canActivate() {
    const demoToken = sessionStorage.getItem('demoToken');
    if (sessionStorage.getItem('isStudentLoggedin')) {
      return true;
    } else if(demoToken){
      return true;
    }

    // navigate to login page
    this.router.navigate(['/home']);
    // you can save redirect url so after authing we can move them back to the page they requested
    return false;
  }

}
