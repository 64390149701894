import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  $baseUrl = environment.baseUrl;

  constructor(public router: Router, public http: HttpClient) { }

  //verify schoolecode
  verifySchoolcode(value) {
    let data = {
      referral_code: value.referelcode
    }
    return this.http.post(this.$baseUrl + 'schools/verify/referral_code/', data );
  }

  //get username list
  getUsernamelist(){
    return this.http.get( this.$baseUrl + 'accounts/student/usernames/list/' );
  }

  //get avatar list
  getAvatarlist(){
    return this.http.get( this.$baseUrl + 'accounts/student/avatars/list/' );
  }

  //student signup
  studentSignup(data){
    return this.http.post(this.$baseUrl + 'accounts/student/signup/', data );
  }

  //generate username
  generateUsername(data){
    return this.http.post(this.$baseUrl + 'accounts/generate/username/', data );
  }

  //students login
  login(data){
    return this.http.post(this.$baseUrl + 'accounts/student/login/', data );
  }

  //student logout
  logout() {
    sessionStorage.removeItem('isStudentLoggedin');
    sessionStorage.removeItem('studentData');
    sessionStorage.removeItem('quizSectionId');
    sessionStorage.removeItem('quizChapterId');
    sessionStorage.removeItem('gameStatus');
    sessionStorage.removeItem('studentDetails');
    sessionStorage.removeItem('totalPoints');
    sessionStorage.removeItem('progressUnlock');
    sessionStorage.removeItem('progressImages');
    this.router.navigateByUrl('home');
  }
}
