import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataService } from 'src/shared/services/data/data.service';

@Component({
  selector: 'app-cec-points',
  templateUrl: './cec-points.component.html',
  styleUrls: ['./cec-points.component.scss']
})
export class CecPointsComponent implements OnInit {

  ponitsInfo:any;
  schoolActivityPerc : any;
  globalActivityPerc : any;
  constructor(
    public dialogRef: MatDialogRef<CecPointsComponent>,
    public dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.ponitsInfo = data;
    this.schoolActivityPerc = this.ponitsInfo.school_activity.total_donation / this.ponitsInfo.school_activity.target_budget;
    this.globalActivityPerc = this.ponitsInfo.global_activity.total_donation / this.ponitsInfo.global_activity.target_budget;
    // console.log(data);
   }

   onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

  close(){
    this.dialogRef.close();
  }

  // mouse over sound
  playSound(){
    this.dataService.hoverSound.emit('play');
  }
}
