import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AuthService } from "src/shared/services/auth/auth.service";
import { DataService } from "src/shared/services/data/data.service";

@Component({
  selector: "app-user-settings",
  templateUrl: "./user-settings.component.html",
  styleUrls: ["./user-settings.component.scss"],
})
export class UserSettingsComponent implements OnInit {
  language: string = "en";
  languages = [
    {
      name: "English",
      value: "en",
    },
    {
      name: "Nederlands",
      value: "nl",
    },
    // {
    //   name: 'German',
    //   value : 'de'
    // }
  ];
  showLangDropdown = false;

  constructor(
    public dialogRef: MatDialogRef<UserSettingsComponent>,
    public authService: AuthService,
    public dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    let a = sessionStorage.getItem("lang");
    // console.log(a);
    if (a != null && a != undefined) {
      this.language = a;
    } else {
      this.language = "nl";
    }
    // console.log(this.data);
  }

  showDropdown() {
    this.showLangDropdown = !this.showLangDropdown;
  }

  selectLang(value) {
    this.showLangDropdown = !this.showLangDropdown;
    this.language = value;
    let langId = value == "nl" ? "nl" : "en-us";
    // this.dataService.changeLanguage(langId).subscribe((res: any) => {
    //   console.log(res);
    // })
    localStorage.setItem("langId", langId);
    this.dataService.language.emit(value);
    this.dialogRef.close();
  }

  privacyPolicy() {
    this.dialogRef.close();
  }

  logOut() {
    this.authService.logout();
    this.dialogRef.close();
  }
}
