import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService } from 'src/shared/services/data/data.service';

@Component({
  selector: 'app-media-enlarger',
  templateUrl: './media-enlarger.component.html',
  styleUrls: ['./media-enlarger.component.scss']
})
export class MediaEnlargerComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<MediaEnlargerComponent>,
    private sanitizer: DomSanitizer,
    public dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    // console.log(this.data);
    if(this.data.type == 4 || this.data.type == 3){
      let sound = sessionStorage.getItem('music');
      if( sound == 'on') {
        this.dataService.sound.emit(2);
      }
      // this.data.video = this.data.video+'?autoplay=1&controls=0';&rel=0
      if(this.data.type == 4 ){
        this.data.video = this.data.video+'?autoplay=1&controls=1&showinfo=0&loop=1&rel=0';
      }
      this.data.video = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.video);
    }
  }

  close(){
    let sound = sessionStorage.getItem('music');
    if( sound == 'on') {
      this.dataService.sound.emit(1);
    }
    this.dialogRef.close();
  }

   // mouse over sound
   playSound(){
    this.dataService.hoverSound.emit('play');
  }
}
