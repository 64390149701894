import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-result-image-enlarger',
  templateUrl: './result-image-enlarger.component.html',
  styleUrls: ['./result-image-enlarger.component.scss']
})
export class ResultImageEnlargerComponent implements OnInit {

  image:any;
  constructor(
    public dialogRef: MatDialogRef<ResultImageEnlargerComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    // console.log(data);
    this.image = data.image;
   }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

  close(){
    this.dialogRef.close();
  }

}
