import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor( private _router: Router) {

  }

  canActivate(): boolean {
    // console.log(sessionStorage.getItem('isStudentLoggedin'));
    // console.log(sessionStorage.getItem('studentData'));
    if (sessionStorage.getItem('isStudentLoggedin')) {
        return true;
    }

    // navigate to login page
    this._router.navigate(['/home']);
    // you can save redirect url so after authing we can move them back to the page they requested
    return false;
  }

}
