import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { DataService } from 'src/shared/services/data/data.service';

@Component({
  selector: 'app-coin-conversion',
  templateUrl: './coin-conversion.component.html',
  styleUrls: ['./coin-conversion.component.scss']
})
export class CoinConversionComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<CoinConversionComponent>,
    public dataService: DataService,
  ) {
   }

  ngOnInit() {
  }

  close(){
    this.dialogRef.close();
  }

   // mouse over sound
   playSound(){
    this.dataService.hoverSound.emit('play');
  }

}
