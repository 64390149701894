import { NgModule } from '@angular/core';
import { Routes, RouterModule, CanActivate } from '@angular/router';
import { AuthGuardService as AuthGuard  } from 'src/shared/guards/auth-guard.service';
import { IsLoggedIn } from 'src/shared/guards/isLoggedIn';
import { IsQuizSelected } from 'src/shared/guards/isQuizSelected';
import { PresentationComponent } from './components/presentation/presentation.component';
import { isUserExists } from 'src/shared/guards/isUserExists';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'presentation/:id', component: PresentationComponent},
  { path: 'presentation', component: PresentationComponent},
  { path: 'home', loadChildren: () => import('./home-page/home-page.module').then(m => m.HomePageModule), canActivate: [IsLoggedIn]},
  { path: 'demo',loadChildren: () => import('./demo/demo.module').then(m => m.DemoModule)},
  { path: 'dashboard',loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuard]},
  { path: 'general-quiz',loadChildren: () => import('./quiz/quiz.module').then(m => m.QuizModule), canActivate: [AuthGuard,IsQuizSelected]},
  { path: 'landscape-quiz',loadChildren: () => import('./landscape-quiz/landscape-quiz.module').then(m => m.LandscapeQuizModule),
    canActivate: [AuthGuard,IsQuizSelected]},
  { path: 'world-quiz',loadChildren: () => import('./world-quiz/world-quiz.module').then(m => m.WorldQuizModule),
    canActivate: [AuthGuard,IsQuizSelected]},
  { path: 'my-forest',loadChildren: () => import('./my-forest/my-forest.module').then(m => m.MyForestModule),
  canActivate: [isUserExists]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
