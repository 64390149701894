import { Injectable, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuizService {
  private subject = new Subject<any>();
  $baseUrl = environment.baseUrl;
  constructor(
    public router: Router,
    public http: HttpClient,
  ) { }

  //get quiz sections
  getQuizSections(){
    return this.http.get( this.$baseUrl + 'quiz/sections/get/' );
  }

  //selected section quiz list
  getSectionQuiz(id){
    return this.http.get( this.$baseUrl + 'quiz/section/'+id+'/quizs/' );
  }

  //selected section details
  getSectionDetails(id){
    return this.http.get( this.$baseUrl + 'quiz/section/'+id+'/get/' );
  }

  //get quizDetails
  getQuizDetails(id){
    return this.http.get( this.$baseUrl + 'quiz/'+id+'/get/' );
  }

  //save result
  saveResult(id,data){
    return this.http.patch(this.$baseUrl + 'quiz/answer/'+id+'/save/', data);
  }

  //get demo quiz
  getDemoQuiz(){
    return this.http.get( this.$baseUrl + 'quiz/demo/get/' );
  }

  //demo register
  demoRegister(data){
    return this.http.post(this.$baseUrl + 'quiz/demo/register/', data );
  }

  //save demo result
  saveDemoResult(data){
    return this.http.post(this.$baseUrl + 'quiz/demo/answer/save/', data);
  }

  // get demo progress images
  getDemoProgressImages(){
    return this.http.get( this.$baseUrl + 'quiz/progress/images/public/' );
  }

  // get demo chapter unlock order
  getDemoUnlockOrder(){
    return this.http.get( this.$baseUrl + 'quiz/chapter/unlock-order/public/' );
  }


}
