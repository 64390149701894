import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DataService } from 'src/shared/services/data/data.service';
import { NgbPopoverConfig, NgbPopover } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-qrcode-enlarger',
  templateUrl: './qrcode-enlarger.component.html',
  styleUrls: ['./qrcode-enlarger.component.scss'],
  providers: [NgbPopoverConfig],
})
export class QrcodeEnlargerComponent implements OnInit {

  qrCode;
  constructor(
    public dialogRef: MatDialogRef<QrcodeEnlargerComponent>,
    public dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public data,
    config: NgbPopoverConfig
  ) {
    config.placement = 'bottom-left';
    config.triggers = 'manual';
    this.qrCode = data.qrcode;
   }

   onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

  close(){
    this.dialogRef.close();
  }

  // mouse over sound
  playSound(){
    this.dataService.hoverSound.emit('play');
  }

  //download qrcode
  downloadQrcode(parent) {
    // fetches base 64 date from image
    // console.log(parent);
    // const parentElement = parent.nativeElement.querySelector("img").src;
    const parentElement = parent.qrcElement.nativeElement.querySelector("img").src;

    // converts base 64 encoded image to blobData
    let blobData = this.convertBase64ToBlob(parentElement);

    // saves as image
    if (window.navigator && window.navigator.msSaveOrOpenBlob) { //IE
      window.navigator.msSaveOrOpenBlob(blobData, 'Qrcode');
    } else { // chrome
      const blob = new Blob([blobData], { type: "image/png" });
      const url = window.URL.createObjectURL(blob);
      // window.open(url);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'Qrcode';
      link.click();
    }

    // if (window.navigator.msSaveOrOpenBlob) // IE10+
    //   window.navigator.msSaveOrOpenBlob(blobData, 'Qrcode');
    // else {
    //   const blob = new Blob([blobData], { type: "image/png" });
    //   var url = window.URL.createObjectURL(blob);
    //   window.open(url);
    // }
  }

  private convertBase64ToBlob(Base64Image: any) {
    // SPLIT INTO TWO PARTS
    const parts = Base64Image.split(';base64,');
    // HOLD THE CONTENT TYPE
    const imageType = parts[0].split(':')[1];
    // DECODE BASE64 STRING
    const decodedData = window.atob(parts[1]);
    // CREATE UNIT8ARRAY OF SIZE SAME AS ROW DATA LENGTH
    const uInt8Array = new Uint8Array(decodedData.length);
    // INSERT ALL CHARACTER CODE INTO UINT8ARRAY
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }
    // RETURN BLOB IMAGE AFTER CONVERSION
    return new Blob([uInt8Array], { type: imageType });
  }

    //popove message
    openPopover(popoverHook: NgbPopover) {
      // console.log("dshbfjhsd", popoverHook)
      popoverHook.open();
      setTimeout(()=>{ popoverHook.close(); }, 2000)
      // if(popoverHook.isOpen()){
      //   popoverHook.close();
      // } else{
      //   popoverHook.open();
      // }
    }
}
