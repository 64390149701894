import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';

import { PresentationPopUpComponent } from '../dialogs/presentation-pop-up/presentation-pop-up.component';
import { DataService } from 'src/shared/services/data/data.service';
import { LoaderService } from 'src/shared/services/loader/loader.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-presentation',
  templateUrl: './presentation.component.html',
  styleUrls: ['./presentation.component.scss']
})
export class PresentationComponent implements OnInit {

  pageNo = 1;
  popupOpen = false;
  donationData: any;
  schoolActivityPerc: any;
  globalActivityPerc: any;
  referalCode: any;
  donateUrl = environment.donateUrl;

  constructor(
    public dialog: MatDialog,
    private dataService: DataService,
    public loaderService: LoaderService,
    public router: Router,
    private activeRroute: ActivatedRoute,
  ) { }

  ngOnInit() {
    const id = this.activeRroute.snapshot.paramMap.get('id');
    const currentUser = JSON.parse(sessionStorage.getItem('studentData'));
    if (currentUser) {
      // console.log(currentUser)
      this.getDonationInfoData();
    } else if (id) {
      // console.log(id);
      this.referalCode = id;
      this.getDonationInfoDataShared();
    } else {
      this.router.navigate(['dashboard']);

    }
  }


  // get donation info
  getDonationInfoData() {
    this.loaderService.display(true);
    this.dataService.getDonationInfo()
      .subscribe((rsp: any) => {
        // console.log("donation info data", rsp);
        this.donationData = rsp;
        this.schoolActivityPerc = this.donationData.school.total_donation / this.donationData.school.target_budget;
        this.globalActivityPerc = this.donationData.global.total_donation / this.donationData.global.target_budget;
        this.loaderService.display(false);
      }, Error => {
        console.log(Error);
        this.loaderService.display(false);
      });
  }

  // get donation info from referal code
  getDonationInfoDataShared() {
    this.loaderService.display(true);
    this.dataService.validateReferalCode(this.referalCode)
      .subscribe((rsp: any) => {
        // console.log("donation info data", rsp);
        this.donationData = rsp;
        this.schoolActivityPerc = this.donationData.school.total_donation / this.donationData.school.target_budget;
        this.globalActivityPerc = this.donationData.global.total_donation / this.donationData.global.target_budget;
        this.loaderService.display(false);
      }, Error => {
        console.log(Error);
        this.loaderService.display(false);
      });
  }

  nextPage() {
    this.pageNo++;
  }

  playSound() {
    this.dataService.hoverSound.emit('play');
  }

  skip() {
    // this.pageNo = 1;
    // this.popupOpen = true;
    // if(this.pageNo == 1)
    // {
    //   this.openModal();
    // }
    if (this.pageNo != 4) {
      this.openModal();
    } else {
      // this.router.navigate(['dashboard/recieve-donation']);
      // this.router.navigate(['http://myclimateaction.cied.in/sponsor/#/student/'+this.donationData.referral_code]);
      var url = this.donateUrl + 'student/' + this.donationData.referral_code;
      window.open(url, "_blank");
      // this.router.navigate(['donation/'+this.donationData.referral_code]);
    }
    // this.pageNo = 5;

    // this.pageNo = this.pageNo+4;

  }

  openModal() {
    const dialogRef = this.dialog.open(PresentationPopUpComponent, {
      disableClose: true,
      panelClass: 'presentation-pop-up',
      width: '30em',
      height: 'auto'
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed', result);
      this.popupOpen = false;
      if (result == 1) {
        // this.router.navigate(['dashboard/recieve-donation']);
        var url = this.donateUrl + 'student/' + this.donationData.referral_code;
        window.open(url, '_blank');
        // this.router.navigate(["http://myclimateaction.cied.in/sponsor/#/student/"+this.donationData.referral_code]);
        // this.router.navigate(['donation/'+this.donationData.referral_code]);
      }
    });
  }
}
