import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CoinConversionComponent } from '../coin-conversion/coin-conversion.component';
import { DataService } from 'src/shared/services/data/data.service';
import { LoaderService } from 'src/shared/services/loader/loader.service';


@Component({
  selector: 'app-free-animal',
  templateUrl: './free-animal.component.html',
  styleUrls: ['./free-animal.component.scss']
})
export class FreeAnimalComponent implements OnInit {
  purchaseSuccess: boolean;
  animal;
  animals =[];
  id;
  progressPerc = 100;
  progressImg = '';
  freeImg = '';
  selectedIndex;
  imgData = [
    {
      id: 'k3x7K',
      img: '../../../../assets/images/anaconda.png',
      freeImg: '../../../../assets/images/snake.png'
    },
    {
      id: 'lZgGZ',
      img: '../../../../assets/images/jaguar.png',
      freeImg: '../../../../assets/images/Leopard.svg'
    },
    {
      id: 'PKNPD',
      img: '../../../../assets/images/parrot.png',
      freeImg: '../../../../assets/images/Parrot_unlocked.png'
    },
    {
      id: 'm30l3',
      img: '../../../../assets/images/monkey_shadow.png',
      freeImg: '../../../../assets/images/Monkey_unlocked.png'
    },
    {
      id: 'm3qnD',
      img: '../../../../assets/images/toucan.png',
      freeImg: '../../../../assets/images/Hornbill.png'
    }
  ];
  unloackedAnimal: any;


  constructor(
    public dialogRef: MatDialogRef<FreeAnimalComponent>,
    private dataService: DataService, @Inject(MAT_DIALOG_DATA) public data: any,
    public loaderService: LoaderService,
  ) {
  }

  ngOnInit() {
    // console.log(this.data);
    // this.animal = this.data.animal;
    this.animals = this.data.animals;
    this.selectedIndex = this.data.index;
    // console.log('animal ', this.animal);
    // this.imgData.forEach(element => {
    //   if (element.id == this.animal.id) {
    //     this.progressImg = element.img;
    //     this.freeImg = element.freeImg;
    //   }
    // });
    this.imgData.forEach(element => {
      this.animals.forEach(animal => {
        if (element.id == animal.id) {
          animal.progressImg = element.img;
          animal.freeImg = element.freeImg;
        }
        if (animal.status.is_buyable || animal.status.unlocked) {
          animal.progressPerc = 100;
        } else {
          animal.progressPerc = 50;
        }
      });
    });

    // if (this.animal.status.is_buyable || this.animal.status.unlocked) {
    //   this.progressPerc = 100;
    // } else {
    //   this.progressPerc = 50;
    // }

    // this.purchaseSuccess = this.animal.status.unlocked;
    // if (this.animal.status.unlocked) {
    //   this.dataService.showRayOverlay();
    // }
    // this.unloackedAnimal = this.animals[4];
    // this.purchaseSuccess = true;
  }

  close(refresh?: any) {
    this.dataService.hideRayOverlay();
    if (refresh == 1) {
      this.dialogRef.close('unlocked');
    } else {
      this.dialogRef.close();
    }
  }

  unlockAnimal(animal) {
    this.loaderService.display(true);
    let data = {
      student: JSON.parse(sessionStorage.getItem('studentData')).id,
      unlocked_item: animal.id
    }
    this.dataService.purchaseAnimal(data)
      .subscribe((res: any) => {
        // console.log(res);
        this.unloackedAnimal = animal;
        this.loaderService.display(false);
        this.purchaseSuccess = true;
        this.dataService.showRayOverlay();
        // this.dialogRef.close('unlocked');
      }, Error => {
        this.loaderService.display(false);
        console.log(Error);
      })
  }

  // mouse over sound
  playSound() {
    this.dataService.hoverSound.emit('play');
  }

  viewForest(){
    this.dialogRef.close('forest');
  }

}
