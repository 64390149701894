import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import totp from 'totp-generator';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add authorization header with basic auth credentials if available
    const currentUser = JSON.parse(sessionStorage.getItem('studentData'));
    const demoToken = sessionStorage.getItem('demoToken');
    let langId = localStorage.getItem('langId');
    if (currentUser) {
      request = request.clone({
        setHeaders: {
          Bearer: `${currentUser.token}`,
          'user-id': `${currentUser.id}`,
          'local-timestamp': String(Math.floor((new Date().getTime() / 1000))),
          'language': langId ? langId : 'nl'
        }
      });
    } else if (demoToken) {
      request = request.clone({
        setHeaders: {
          Bearer: demoToken,
          'local-timestamp': String(Math.floor((new Date().getTime() / 1000)))
        }
      });
    } else {
      let otp = totp('SAJDSJADMEIJCXLZSA');
      // console.log(otp)
      request = request.clone({
        setHeaders: {
          // Bearer: 'hsdbcsd@#@$WESfdsnsAw8w434956jsda360ohewq2739812',
          'Otp': otp,
          'local-timestamp': String(Math.floor((new Date().getTime() / 1000)))
        }
      });
    }

    return next.handle(request);
  }
}
