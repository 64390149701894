import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class IsLoggedIn implements CanActivate {
  constructor(private router: Router) { }

  canActivate() {
    if (sessionStorage.getItem('isStudentLoggedin')) {
      return this.isVerified();
    } else {
      return true;
    }
  }

  isVerified() {
    let user = JSON.parse(sessionStorage.getItem('studentData'));
    if (user) {
      this.router.navigate(['/']);
    } else {
      return true;
    }
  }

}
