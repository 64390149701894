import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { DataService } from 'src/shared/services/data/data.service';
import { LoaderService } from 'src/shared/services/loader/loader.service';
import { ProgressImageEnlargerComponent } from '../progress-image-enlarger/progress-image-enlarger.component';

@Component({
  selector: 'app-progress-images',
  templateUrl: './progress-images.component.html',
  styleUrls: ['./progress-images.component.scss']
})
export class ProgressImagesComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ProgressImagesComponent>,
    public dialog: MatDialog,
    private dataService: DataService, @Inject(MAT_DIALOG_DATA) public data: any,
    public loaderService: LoaderService,
  ) {
    console.log(data);
  }

  ngOnInit() {
  }

  close(refresh?: any) {
    this.dataService.hideRayOverlay();
    if (refresh == 1) {
      this.dialogRef.close('unlocked');
    } else {
      this.dialogRef.close();
    }
  }

  openProgrssImage(i){
    this.dialogRef.close();
    //open progress images modal
    let data = {
      progressImage : this.data.progressImage,
      index:i,
      progressCompleted : this.data.progressCompleted
    };
    const dialogRef = this.dialog.open(ProgressImageEnlargerComponent, {
      disableClose: false,
      width: '67.5em',
      height: 'auto',
      data: data,
      panelClass: 'progress-img-enlarger'
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed', result);
    });
  }


  playSound() {
    this.dataService.hoverSound.emit('play');
  }

}
