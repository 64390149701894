import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { QuizRulesComponent } from './components/dialogs/quiz-rules/quiz-rules.component';
import { MediaEnlargerComponent } from './components/dialogs/media-enlarger/media-enlarger.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { AuthGuardService as AuthGuard } from 'src/shared/guards/auth-guard.service';
import { AuthService } from 'src/shared/services/auth/auth.service';
import { LoaderService } from 'src/shared/services/loader/loader.service';
import { AuthInterceptor } from 'src/shared/services/interceptor';
import { QuizService } from 'src/shared/services/quiz/quiz.service';
import { QrcodeEnlargerComponent } from './components/dialogs/qrcode-enlarger/qrcode-enlarger.component';
import { CecPointsComponent } from './components/dialogs/cec-points/cec-points.component';
import { GameStatusComponent } from './components/dialogs/game-status/game-status.component';
import { PresentationPopUpComponent } from './components/dialogs/presentation-pop-up/presentation-pop-up.component';
import { PresentationComponent } from './components/presentation/presentation.component';
import { MatDialogModule, MatRadioModule, MatIconModule } from '@angular/material';
import { ResultImageEnlargerComponent } from './components/dialogs/result-image-enlarger/result-image-enlarger.component';
import { UserFeedbackComponent } from './components/dialogs/user-feedback/user-feedback.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { StarRatingModule } from 'angular-star-rating';
import { AnimalsInfoComponent } from './components/dialogs/animals-info/animals-info.component';
import { UserSettingsComponent } from './components/dialogs/user-settings/user-settings.component';
import { QRCodeModule } from 'angularx-qrcode';
import { McaCoinsComponent } from './components/dialogs/mca-coins/mca-coins.component';
import { CoinConversionComponent } from './components/dialogs/coin-conversion/coin-conversion.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RemoveCommaPipe } from 'src/shared/pipes/remove-thousand-factor.pipe';
import { FreeAnimalComponent } from './components/dialogs/free-animal/free-animal.component';
import { ProgressImagesComponent } from './components/dialogs/progress-images/progress-images.component';
import { ProgressImageEnlargerComponent } from './components/dialogs/progress-image-enlarger/progress-image-enlarger.component';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    QuizRulesComponent,
    MediaEnlargerComponent,
    QrcodeEnlargerComponent,
    CecPointsComponent,
    GameStatusComponent,
    PresentationPopUpComponent,
    PresentationComponent,
    ResultImageEnlargerComponent,
    UserFeedbackComponent,
    AnimalsInfoComponent,
    UserSettingsComponent,
    McaCoinsComponent,
    CoinConversionComponent,
    RemoveCommaPipe,
    FreeAnimalComponent,
    ProgressImagesComponent,
    ProgressImageEnlargerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    MatRadioModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatIconModule,
    HttpClientModule,
    QRCodeModule,
    NgbModule,
    StarRatingModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    RemoveCommaPipe
  ],
  entryComponents: [
    QuizRulesComponent,
    MediaEnlargerComponent,
    QrcodeEnlargerComponent,
    CecPointsComponent,
    GameStatusComponent,
    PresentationPopUpComponent,
    ResultImageEnlargerComponent,
    UserFeedbackComponent,
    AnimalsInfoComponent,
    UserSettingsComponent,
    McaCoinsComponent,
    CoinConversionComponent,
    FreeAnimalComponent,
    ProgressImagesComponent,
    ProgressImageEnlargerComponent
  ],
  providers: [
    AuthGuard,
    AuthService,
    LoaderService,
    QuizService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
