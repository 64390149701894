import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material'
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material';
import { DataService } from 'src/shared/services/data/data.service';
import { LoaderService } from 'src/shared/services/loader/loader.service';
import { CoinConversionComponent } from '../coin-conversion/coin-conversion.component';

@Component({
  selector: 'app-animals-info',
  templateUrl: './animals-info.component.html',
  styleUrls: ['./animals-info.component.scss']
})
export class AnimalsInfoComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AnimalsInfoComponent>, private router: Router,
    private dataService: DataService, @Inject(MAT_DIALOG_DATA) public data: any,
    public loaderService: LoaderService,
    public dialog: MatDialog,
  ) { }

  animal;
  id;

  ngOnInit() {
    this.animal = this.data.animal;
    // console.log('animal ', this.animal)
  }

  playSound() {
    this.dataService.hoverSound.emit('play');
  }

  close() {
    this.dialogRef.close();
  }

  backToHomePage() {
    this.router.navigate(['../../../dashboard/my-forest']);
    this.dialogRef.close();
  }

  unlockAnimal() {
    this.loaderService.display(true);
    let data = {
      student: JSON.parse(sessionStorage.getItem('studentData')).id,
      unlocked_item: this.animal.id
    }
    this.dataService.purchaseAnimal(data)
      .subscribe((res: any) => {
        // console.log(res);
        this.loaderService.display(false);
        this.dialogRef.close('unlocked');
      }, Error => {
        this.loaderService.display(false);
        console.log(Error);
      })
  }

  //coin convertion modal
  openCoinConvertionModal() {
    const dialogRef = this.dialog.open(CoinConversionComponent, {
      width: '50.25em',
      height: 'auto',
      panelClass: 'coin-conversion',
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed', result);
    });
  }

}
