import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DataService } from 'src/shared/services/data/data.service';

@Component({
  selector: 'app-quiz-rules',
  templateUrl: './quiz-rules.component.html',
  styleUrls: ['./quiz-rules.component.scss']
})
export class QuizRulesComponent implements OnInit {

  quizRules = [];
  constructor(
    public dialogRef: MatDialogRef<QuizRulesComponent>,
    public dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    // console.log(data);
    this.quizRules = data.quizRules;
   }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

  close(){
    this.dialogRef.close();
  }

  // mouse over sound
  playSound(){
    this.dataService.hoverSound.emit('play');
  }

}
