import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class IsQuizSelected implements CanActivate {
  constructor(private router: Router) { }

  canActivate() {
    if (sessionStorage.getItem('quizChapterId')) {
      return true;
    } else {
      this.router.navigate(['/']);
    }
  }

}
