import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService } from 'src/shared/services/data/data.service';

@Component({
  selector: 'app-game-status',
  templateUrl: './game-status.component.html',
  styleUrls: ['./game-status.component.scss']
})
export class GameStatusComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<GameStatusComponent>,
    private sanitizer: DomSanitizer,
    public dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    // console.log(this.data);
  }

  close(){
    this.dialogRef.close();
  }

   // mouse over sound
   playSound(){
    this.dataService.hoverSound.emit('play');
  }

}
