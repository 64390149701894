import { Injectable, EventEmitter } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  $baseUrl = environment.baseUrl;
  sound = new EventEmitter<any>();
  background = new EventEmitter<any>();
  language = new EventEmitter<any>();
  scoreValue = new EventEmitter<any>();
  hoverSound = new EventEmitter<any>();
  demIntro: any;
  demoQstn: any;
  score: any = 0;
  // rayOverlay = document.getElementById('ray-overlay');
  private emitChangeSource = new Subject<any>();

  changeEmitted = this.emitChangeSource.asObservable();

  constructor(
    public http: HttpClient
  ) {
    this.demIntro = sessionStorage.getItem('demIntro');
    this.demoQstn = sessionStorage.getItem('demoQstn');
    this.score = sessionStorage.getItem('score');
  }

  emitChange(data) {
      this.emitChangeSource.next(data);
  }

  updateDemoIntro(num) {
    sessionStorage.setItem('demIntro', num);
    this.demIntro = num;
  }

  updateDemoQstn(num) {
    sessionStorage.setItem('demoQstn', num);
    this.demoQstn = num;
  }

  updateScore(num) {
    sessionStorage.setItem('score', num);
    this.score = num;
    this.scoreValue.emit(this.score);
  }

  // get quizDetails
  getStudentsDetails() {
    let id = JSON.parse(sessionStorage.getItem('studentData')).id;
    return this.http.get(this.$baseUrl + 'accounts/student/' + id + '/');
  }

  // update student details
  updateStudentDetails(data){
    let id = JSON.parse(sessionStorage.getItem('studentData')).id;
    return this.http.patch(this.$baseUrl + 'accounts/student/' + id + '/',data);
  }

  // get ip address
  getIpAddress() {
    return this.http.get('http://api.ipify.org/?format=json');
  }

  // save user feed back
  saveUserFeedback(data) {
    return this.http.patch(this.$baseUrl + 'quiz/demo/feedback/save/', data);
  }

  // get age group
  getAgeGroup() {
    return this.http.get(this.$baseUrl + 'schools/age-group/get/');
  }

  // get demo age group
  getDemoAgeGroup(){
    return this.http.get(this.$baseUrl + 'schools/age-group/demo/get/');
  }

  // get forest details
  getForestDetails() {
    return this.http.get(this.$baseUrl + 'store/animals/get/');
  }

  // unlock animal
  purchaseAnimal(data) {
    return this.http.post(this.$baseUrl + 'store/animals/purchase/', data)
  }

  // get donation data
  getDonationData() {
    return this.http.get(this.$baseUrl + 'finance/donate/code/get/');
  }

  // get user profile
  getUserProfileData() {
    return this.http.get(this.$baseUrl + 'activity/my-profile/get/');
  }

  // get user home activity
  getUserHomeActivity() {
    return this.http.get(this.$baseUrl + 'activity/home/get/');
  }

  // get user school activity
  getUserSchoolActivity() {
    return this.http.get(this.$baseUrl + 'activity/school/get/');
  }

  // get user global activity
  getUserGlobalActivity() {
    return this.http.get(this.$baseUrl + 'activity/global/get/');
  }

  // get donation list
  getDonationList() {
    return this.http.get(this.$baseUrl + 'finance/donation/get/');
  }

  // get my school activity
  getSchoolActivity() {
    return this.http.get(this.$baseUrl + 'schools/activity/profile/get/');
  }

  // get my school activity
  getSchoolActivityById(id) {
    return this.http.get(this.$baseUrl + 'schools/' + id + '/');
  }

  // donation info
  getDonationInfo() {
    return this.http.get(this.$baseUrl + 'schools/donation/info/');
  }

  // validate referal code
  validateReferalCode(code) {
    return this.http.get(this.$baseUrl + 'schools/donation/info/public/?referral_hash=' + code);
  }

  // regiset donar
  registerDoner(data) {
    return this.http.post(this.$baseUrl + 'finance/donar/register/', data);
  }

  // donate amount
  donateAmount(data) {
    return this.http.post(this.$baseUrl + 'finance/donate/', data);
  }

  // get home activities
  getHomeActivities() {
    return this.http.get(this.$baseUrl + 'activity/home/get/public/');
  }

  checkHomeActivity(data) {
    return this.http.post(this.$baseUrl + 'activity/home/participate/', data);
  }

  nlAmtToDecimal(num) {
    let amt = num.toString().split(',');
    if (amt.length < 2) {
      return Number(amt[0]);
    } else {
      return Number(amt[0] + '.' + amt[1]);
    }
  }

  getDemoqstn() {
    return this.demoQstn;
  }
  getDemoIntro() {
    return this.demIntro;
  }
  getScore() {
    return this.score;
  }

  showRayOverlay() {
    let rayOverlay = document.getElementById('ray-overlay');
    rayOverlay.style.display = 'flex';
  }

  hideRayOverlay() {
    let rayOverlay = document.getElementById('ray-overlay');
    rayOverlay.style.display = 'none';
  }

  // get progress image
  getProgressImage(){
    return this.http.get(this.$baseUrl + 'quiz/progress/images/');
  }

  // get progress unloacking order
  getProgressUnloackOrder(){
    return this.http.get(this.$baseUrl + 'quiz/chapter/unlock-order/');
  }

  // getc student progress
  getStudentProgress(){
    let id = JSON.parse(sessionStorage.getItem('studentData')).id;
    return this.http.get(this.$baseUrl + 'accounts/student/' + id + '/progress/');
  }

  // get animal progrss
  getAnimalProgress(){
    return this.http.get(this.$baseUrl + 'store/animals/list/');
  }

  changeLanguage(lang){
    let param = { 'language' : lang}
    return this.http.post( this.$baseUrl + 'accounts/change-language/',param);
  }
}
