import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from 'src/shared/services/data/data.service';
import { FormBuilder, Validators } from '@angular/forms';
import { LoaderService } from 'src/shared/services/loader/loader.service';

@Component({
  selector: 'app-user-feedback',
  templateUrl: './user-feedback.component.html',
  styleUrls: ['./user-feedback.component.scss']
})
export class UserFeedbackComponent implements OnInit {

  feedbackForm = this.fb.group({
    playRating: ['', Validators.required],
    questionRating: ['', Validators.required],
    continuePlaying: ['', Validators.required],
    feedbackMsg: ['', Validators.required],
  });

  submitBtnClicked = false;

  constructor(
    public dialogRef: MatDialogRef<UserFeedbackComponent>,
    public dataService: DataService,
    private fb: FormBuilder,
    public loaderService: LoaderService,
    // @Inject(MAT_DIALOG_DATA) public data
  ) {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    // this.loaderService.display(true);
  }

  close() {
    this.dialogRef.close();
  }



  //feed back submitting
  submitFeedback() {
    this.submitBtnClicked = true;
    console.log(this.feedbackForm.value);
    if (this.feedbackForm.invalid) {
      // console.log("invalid")
    }
    else {
      // console.log("submitted")
      this.loaderService.display(true);
      // console.log(this.feedbackForm.value);
      let data = {
        'game_rating': this.feedbackForm.value.playRating,
        'question_rating': this.feedbackForm.value.questionRating,
        'continue_playing': this.feedbackForm.value.continuePlaying,
        'feedback': this.feedbackForm.value.feedbackMsg
      };
      this.dataService.saveUserFeedback(data)
        .subscribe((res: any) => {
          // console.log(res);
          this.loaderService.display(false);
          this.dialogRef.close();
        }, Error => {
          this.loaderService.display(false);
          console.log(Error);
        });
    }

  }

  // mouse over sound
  playSound() {
    this.dataService.hoverSound.emit('play');
  }

}
