import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { DataService } from '../shared/services/data/data.service';
import {TranslateService} from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { LoaderService } from 'src/shared/services/loader/loader.service';
import { Router, NavigationEnd } from '@angular/router';
// import {Howl, Howler} from 'howler';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit  {
  title = 'global-exploration';
  languageChange: Subscription = new Subscription();
  musicChange: Subscription = new Subscription();
  hoverBtn: Subscription = new Subscription();
  loaderStatus: Subscription = new Subscription();
  scrollDisable = false;
  homePage = true;
  portrait = true;
  // sound = new Howl({
  //   src: ['../assets/music/bgm.webm', '../assets/music/bgm.mp3']
  // });
  showLoader: boolean = false;
  @ViewChild('audioOption',{static: true}) audioPlayerRef: ElementRef;
  @ViewChild('hoveSouund',{static: true}) hoverPlayerRef: ElementRef;

  constructor(
    public dataService: DataService,
    public loaderService: LoaderService,
    private router: Router,
    public translate: TranslateService
  ) {
    // document.body.style.fontSize = '1.75vmin';

    //set body font size
    // console.log(window.innerHeight,window.innerWidth);
    if(window.innerHeight < window.innerWidth){
      let a = (window.innerHeight *1/100);
      // console.log(a,1.75*a);
      document.body.style.fontSize = (1.75*a)+'px';
    } else {
      let a = (window.innerWidth *1/100);
      // console.log(a,1.75*a);
      document.body.style.fontSize = (1.75*a)+'px';
    }

    router.events.subscribe((val) => {
      // see also
      if(val instanceof NavigationEnd){
        // console.log(val)
        // console.log( val.urlAfterRedirects.indexOf('/home/') > -1);
        if(val.urlAfterRedirects.indexOf('/home/') > -1){
          this.homePage = true;
        } else{
          this.homePage = false;
        }
        if (window.matchMedia("(orientation: portrait)").matches) {
          // console.log("orientation: portrait");
          this.portrait = true;
        }else{
          // console.log("orientation: landscape");
          this.portrait = false;
        }
      }
      // console.log(val instanceof NavigationEnd)
    });


    // set language
    let language = sessionStorage.getItem('lang');
    if( language != null && language != undefined){
      translate.setDefaultLang(language);
      translate.use(language);
    } else{
      translate.setDefaultLang('nl');
      translate.use('nl');
      sessionStorage.setItem('lang','nl');
    }
    sessionStorage.setItem('music', 'off');
    let sound = sessionStorage.getItem('music');
    // console.log(sound);
    if(sound == null || sound == 'off'){
      sessionStorage.setItem('music', 'off');
      this.loadMusic(2);
    } else {
      sessionStorage.setItem('music', 'on');
      this.loadMusic(1)
    }
    // sessionStorage.setItem('music', 'off');

    //music enable or disable change call
    this.musicChange = this.dataService.sound.subscribe(value => {
      // console.log(value);
      this.loadMusic(value)
    });
    this.hoverBtn  =  this.dataService.hoverSound.subscribe(value => {
      // this.hoverPlayerRef.nativeElement.play();
    });

    // language change call
    this.languageChange  = this.dataService.language.subscribe(value => {
      // console.log(value);
      this._initTranslate(value);
    });

    //resolution change
    window.addEventListener("resize", function() {
      // console.log("resolution changed");
      document.documentElement.style.setProperty('overflow', 'auto')
      const metaViewport = document.querySelector('meta[name=viewport]')
      metaViewport.setAttribute('content', 'height=' + (window.screen.availHeight/1.288) + 'px, width=device-width, initial-scale=1.0')
      // console.log(window);
      window.scrollTo(0, 0);
      if(window.innerWidth > 768){
        // console.log(window.innerHeight,window.innerWidth);
        // console.log(window.screen.availHeight/window.innerHeight);
        if(window.screen.availHeight/window.innerHeight < 2.7) {
          // this.scrollDisable = false;
          if(window.innerHeight < window.innerWidth){
            let a = (window.innerHeight *1/100);
            document.body.style.fontSize = (1.75*a)+'px';
          } else{
            let a = (window.innerWidth *1/100);
            document.body.style.fontSize = (1.75*a)+'px';
          }
          document.getElementById("mainDiv").style.position = 'fixed';
        } else{
          // console.log(document.body);
          document.getElementById("mainDiv").style.position = 'absolute';
        //   this.scrollDisable = true;
        }
      }
    }, false);


    // check mobile orientation
    window.addEventListener("orientationchange", function() {
      // Announce the new orientation number
      // console.log(window.orientation);
      location.reload();

    }, false);

    if( !window.location.hash && window.addEventListener ){
      window.addEventListener( "load",function() {
          setTimeout(function(){
              window.scrollTo(0, 0);
          }, 0);
      });
    }
    // window.scrollTo(0,1)

   }




  //change language
   private _initTranslate(value){
    sessionStorage.setItem('lang', value);
    // console.log(value);
    this.translate.use(value);
   }



   //disable and enable music
   loadMusic(value){
    if(value == 1) {
      setTimeout(()=>{
        this.audioPlayerRef.nativeElement.volume = 0.25;
        this.audioPlayerRef.nativeElement.play();
      },100);
    } else {
      setTimeout(()=>{
        this.audioPlayerRef.nativeElement.pause();
      },100);
    }
   }


    ngOnDestroy() {
      if (this.languageChange) {
        this.languageChange.unsubscribe();
      }
      if (this.musicChange) {
        this.musicChange.unsubscribe();
      }
      if(this.hoverBtn) {
        this.hoverBtn.unsubscribe();
      }
      if(this.loaderStatus) {
        this.loaderStatus.unsubscribe();
      }
    }



    ngOnInit(){



    }

    ngAfterViewInit() {
      this.loaderStatus = this.loaderService.status.subscribe((val: boolean) => {
        this.showLoader = val;
      });
    }
}
