import { Component, OnInit, Inject, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DataService } from 'src/shared/services/data/data.service';

@Component({
  selector: 'app-progress-image-enlarger',
  templateUrl: './progress-image-enlarger.component.html',
  styleUrls: ['./progress-image-enlarger.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressImageEnlargerComponent implements OnInit {

  selectedIndex = 0;
  progressImage = [];
  constructor(
    public dialogRef: MatDialogRef<ProgressImageEnlargerComponent>,
    public dataService: DataService,
    public cdRef:ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    console.log(data)
    // this.progressImage = data.progressImage;
    this.selectedIndex = data.index;
    data.progressImage.forEach((element,i)=> {
      if(i+1 <= data.progressCompleted){
        this.progressImage.push(element);
      }
    });
  }

  ngOnInit() {
    // console.log(this.data);
  }

  ngAfterViewInit() {

    this.cdRef.detectChanges();
  }

  close(){
    let sound = sessionStorage.getItem('music');
    if( sound == 'on') {
      this.dataService.sound.emit(1);
    }
    this.dialogRef.close();
  }

   // mouse over sound
   playSound(){
    this.dataService.hoverSound.emit('play');
  }

}
